import React from "react"
import styles from "./LoginForm.scss"
import BirdBrain from '../images/BirdBrain'
import Link from "gatsby-link"

export default ({ handleSubmit, handleUpdate, handleChange }) => (
  <section className="login-form">
    <form
      className={styles.form}
      method="post"
      onSubmit={event => {
        handleSubmit(event, `/forgotten`)
      }}
    >
      <div className="message">{handleChange.message && <div className="inner">{handleChange.message}</div>}</div>
      <BirdBrain/>
      <div className={`field${handleChange.email ? ' active' : ''}`}>
        <label htmlFor="email">Enter your email address</label>
        <input
          className={styles[`form__input`]}
          type="email"
          name="email"
          onChange={handleUpdate}
          placeholder="Enter your email address"
          value={handleChange.email}
          required
        />
      </div>
      <button>{handleChange.loading ? 'loading' : 'I need a new password'}</button>
      <Link className="sublink" to="/login">Login Page</Link>
    </form>
  </section>
)
